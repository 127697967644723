<template>
    <div id="container">
        <div class="header">
                <van-nav-bar
                title=""
                left-text="返回"
                right-text=""
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
                />
            </div>
            <div class="logo">
                <!-- <img src="../../assets/images/title.png" alt=""> -->
                    <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
            </div>
            <!--  -->
            <div class="content">
                <div class="info">
                    <div class="title" style="font-weight:700">加群活动</div>
                    <div class="tips">加入[与你]不迷路,免费领取200活动积分:</div>
                    <div style="margin-top: 10px;">
                        <img style="width:90%;height: 90%;" src="../../assets/images/yuni.jpg" alt="">
                    </div>
                    <!-- <div class="tips">如果您有长时间不使用的QQ小号可以拿来兑换积分或者会员账号,兑换规则如下:</div> -->
                    <div class="title" style="font-weight:700">兑换活动</div>
                    <div class="tips">如果您有长时间不使用的QQ小号可以拿来兑换积分或者会员账号,兑换规则如下:</div>
                    <div class="line">-- 月亮号一个可兑换588积分,两个可兑换1288积分</div>
                    <div class="line">-- 太阳号一个可兑换永久会员账号</div>
                    <!-- <div class="line_">备注：等级不符号要求的老哥就不要问,想兑换的老哥联系QQ:1538373956</div> -->
                    <!--  -->
                    <!-- <div style="margin-top:20px;font-weight:700"  class="title">其他活动</div>
                    <div class="info-box">
                        <div class="info-box-one" @click="gz28">
                            <div class="left">
                                <img src="../../assets/images/gz28.png" alt="">
                            </div>
                            <div class="right">

                                <i style="margin-left:55px">
                                    <i style="font-size:14px"> 注册即送588积分<br></i>
                                    <i style="font-size:14px">首充即送永久会员任选</i><br>
                                    立即注册</i>
                            </div>
                        </div>
                        <div class="info-box-one two__" @click="ww28">
                            <div class="left">
                                <img style="width:50px" src="../../assets/images/ww.png" alt="">
                            </div>
                            <div class="right">

                                <i style="margin-left:55px">
                                    <i style="font-size:14px">达标请与我联系<br></i>
                                    去领会员</i>
                            </div>
                        </div>

                        <div class="info-box-one" id="three__">
                            旺旺号:7161464 QQ:1538373956
                        </div>
                    </div> -->
                    
                </div>
            
            </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex"

export default {
    data() {
        return {
            username:'',
            phone:"",
            FileName: "JsToJsonFile",
            ShowListData: [],
            infoEnd:[]
        }
    },
    computed:{
         ...mapMutations(["changeFlag","changeFlag1"]),
    },
    created() {
        // this.getTreeData()

        // this.getInfo()
    },
    methods: {

        onClickLeft(){
            window.history.back(-1)
        },
        onClickRight(){

        },
        gz28(){
            window.location.href="https://wcws.xinqia168.com/app/register.php?site_id=1010&topId=108660"
        },
        ww28(){
            window.location.href="http://www.quyutech.cn/desktop"
        }
    },
   async mounted() {
    // this.ces = true
      // this.ces = true
    this.changeFlag();
  },
  destroyed() {
    this.changeFlag1();
  },

}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
    overflow-x: hidden;
}
#container .content .info{
    width: 100%;
    height: 385px;
    padding: 5px;
}
#container .content .info .title{
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    text-align: left;
    color: #ec4899;
}
#container .content .info .tips{
    font-size: 16px;
    text-align: left;
    
}
#container .content .info .line{
    margin-top: 18px;
    font-size: 14px;
    text-align: left;
    /* color: #ec4899; */
}
#container .content .info .line_{
    margin-top: 18px;
    font-size: 14px;
    text-align: left;
    color: #ec4899;
}
#container .content .info .shengji{
  width: 100%;
  height: 50px;
  font-size: 14px;
}
#container .content .info .shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
#container .content .info .shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
#container .content .info li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
#container .content .info .info-box{
    width: 100%;
    height: 200px;
    padding: 5px;
    box-sizing: border-box;
}
#container .content .info .info-box .info-box-one{
    width: 100%;
    height: 85px;
    background-image: linear-gradient( 135deg, #F6CEEC 10%, #D939CD 100%);
    border-radius: 12px;
    padding-left: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
}
#container .content .info .info-box .info-box-one .left{
    width: 35%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}
#container .content .info .info-box .info-box-one .left img{
    width: 100%;
}
.right{
    height: 100%;
    width: 65%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    /* color: #fff; */
    font-weight: 700;
}
.two__{
    background-image: linear-gradient( 135deg, #FFF5C3 10%, #9452A5 100%) !important;
}
#three__{
    height: 40px !important;
    background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%) !important; 
    /* color: #fff; */
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>